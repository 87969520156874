<template>
  <div class="account-security-container">
    <div class="account-security-body bgc_fff">
      <header class="account-security-header flex flex_jcbetween flex_acenter">
        <span class="fontsize_14 color_222">账户安全</span>
      </header>
      <el-row
        v-for="(aitem, aind) in accountList"
        :key="'account-security_' + aind"
        class="account-security-item flex flex_acenter flex_jcbetween"
      >
        <el-col class="">
          <p class="lineheight_20 fontsize_14 color_222">{{ aitem.title }}</p>
          <el-input
            class="lineheight_20 margin_top16 fontsize_14"
            style="width: 60%"
            v-model="activationCode"
            :placeholder="aitem.placeholder"
            v-if="aitem.type == 'activationCode'"
          ></el-input>
          <p
            class="lineheight_20 margin_top16 fontsize_14"
            v-if="aitem.type != 'activationCode'"
            :class="{
              color_6E6E6E: showForm[aitem.propName],
              color_A1A1A1: !showForm[aitem.propName],
            }"
            v-html="(showForm[aitem.propName] || aitem.placeholder)"
          ></p>

        </el-col>
        <el-col class="flex1">
          <customButton
            @click="tapBtn(aitem)"
            :disabled="
              aitem.type === 'wechat' && !showForm.canunbind_wechat
            "
            color="#D4D4D4"
            plain
            :width="102"
            :height="40"
            :roundPX="24"
            :fontSize="14"
          >
            <span
              v-if="aitem.type === 'wechat'"
              class="fontsize_14 color_222"
              >{{
                (showForm.wechat_buttion && showForm.wechat_buttion.text) || ""
              }}</span
            >
            <span
              v-else-if="showForm[aitem.propName] && aitem.changeButtonText"
              class="fontsize_14 color_222"
              >{{ aitem.changeButtonText }}</span
            >
            <span
              v-else-if="!showForm[aitem.propName] && aitem.bindButtonText"
              class="fontsize_14 color_222"
              >{{ aitem.bindButtonText }}</span
            >
            <span v-else class="fontsize_14 color_222">{{
              aitem.buttonText
            }}</span>
          </customButton>
        </el-col>
      </el-row>
    </div>
    <fd-dialog
      :title="dialogDetail.title"
      :visible.sync="dialogDetail.visible"
      width="600px"
      :before-close="handleClose"
      :append-to-body="true"
      custom-class="course-dialog account-dialog not-full"
    >
      <el-form
        v-if="type !== 'wechat'"
        @submit.native.prevent
        :model="ruleForm"
        status-icon
        :rules="rules"
        :show-message="false"
        ref="ruleForm"
        label-width="120px"
        label-position="top"
        class="reset-form"
      >
        <el-form-item
          v-for="(item, index) in currentData"
          :key="item.name"
          :prop="item.props"
          :label="item.name"
        >
          <el-input
            :type="getInputType(item.inputType, item.props)"
            v-model="ruleForm[item.props]"
            autocomplete="off"
            :disabled="getDisableStatus(item, index)"
            :placeholder="item.placeholder"
            class="login-input-wrapper"
          >
            <span
              v-if="item.propName === 'mobileCode' && !mobileCodeData.hasCode"
              slot="suffix"
              class="
                fontsize_12
                color_E74362
                pointer
                valid-code-btn
                hoverColor_D0435E
              "
              :class="{
                'is-disabled':
                  !ruleForm[isNextStep ? 'new-account' : 'account'],
              }"
              @click="getMobileCode"
              >获取验证码</span
            >
            <span
              v-else-if="
                item.propName === 'mobileCode' && mobileCodeData.hasCode
              "
              slot="suffix"
              class="fontsize_12 color_A1A1A1 valid-code-btn"
              >重新获取(<i style="font-style: normal">{{
                mobileCodeData.countdownTime
              }}</i
              >)</span
            >
          </el-input>
          <div class="input-error-msg" v-if="errMsgSet[item.props]">
            <i class="icon-warning fontsize_12"></i
            ><span class="padding_left8 color_E74362 fontsize_12">{{
              errMsgSet[item.props]
            }}</span>
          </div>
          <div
            v-if="index === 0 && !isNextStep && otherMode"
            style="line-height: 32px"
          >
            <p class="fontsize_14 color_A1A1A1">
              改用我的<span
                class="padding_0_4 color_E74362 pointer hoverColor_D0435E"
                @click="changeMode(otherMode.type)"
                >{{ otherMode.title }}</span
              >找回
            </p>
          </div>
        </el-form-item>
        <div
          v-if="type === 'password' && isNextStep"
          class="flex flex_acenter flex_jcbetween"
        >
          <checkBox
            key="showpass"
            :value="isShowPass"
            :label="'显示密码'"
            :labelFontSize="12"
            :scale="0.6"
            :labelPaddingLeft="5"
            @change="changeShowPass"
            class="show-pwd-checkbox"
          >
          </checkBox>
        </div>
      </el-form>
      <span v-if="type !== 'wechat'" slot="footer" class="dialog-footer">
        <customButton
          v-if="!isNextStep"
          @click="tapNextStep"
          color="#E74362"
          :width="78"
          :roundPX="16"
          :height="32"
        >
          <span class="fontsize_12 color_fff">下一步</span>
        </customButton>
        <customButton
          v-else
          @click="tapSubmitForm"
          color="#E74362"
          :width="78"
          :roundPX="16"
          :height="32"
        >
          <span class="fontsize_12 color_fff">确定</span>
        </customButton>
        <customButton
          @click="tapClose"
          color="#D4D4D4"
          :width="78"
          :roundPX="16"
          :height="32"
          class="margin_left20"
        >
          <span class="fontsize_12 color_fff">关闭</span>
        </customButton>
      </span>
      <div v-if="type === 'wechat'" class="wechat-wrapper">
        <div class="wechat-code-wrapper">
          <img
            @click="bindWechatCode"
            :src="wechatData.img"
            alt=""
            class="wechat-code-img"
            loading="lazy"
          />
        </div>
        <p class="textcenter flex flex_acenter">
          <i class="icon-wechat fontsize_16 icon_color_0AC061"></i>
          <span class="fontsize_14 color_222 padding_left4"
            >扫码关注公众号完成绑定</span
          >
        </p>
      </div>
    </fd-dialog>
    <el-dialog
      title
      :visible.sync="dialogApi.visible"
      width="924px"
      custom-class="API-dialog"
      :show-close="false"
      top="30vh"
    >
      <div class="Api_title"><p>为了保证安全，您可以重置AppSecret。注意所有使用现有Appld/Secret的应用需要使用新的AppSecret才能工作。</p></div>
      <div class="from">
        <div>
          <el-input placeholder="AppID：" v-model="APIForm.appid"></el-input>
          <el-button class="copyBtn" v-clipboard:copy="APIForm.appid"
              v-clipboard:success="copySuccess" v-clipboard:error="copyErr">
              复制
              </el-button>
        </div>
        <div>
          <el-input placeholder="AppSecret：" v-model="APIForm.appsecret" :type="SecretType">
            <i slot="suffix" :class="showSecret?'eye-icon-show':'eye-icon-hide'" class="passwordEye" @click="changeShow"></i>
          </el-input>
          <el-button class="copyBtn" v-clipboard:copy="APIForm.appsecret"
              v-clipboard:success="copySuccess" v-clipboard:error="copyErr">
              复制
              </el-button>
      </div>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancleBtn" @click="dialogApi.visible = false">取 消</el-button>
        <el-button class="confirmBtn" type="primary" @click="ResetApi">重置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  sendOfLoginMobileCode,
  sendEmailCodeOfLogin,
  sendEmailCodeOfRegister,
  sendMobileCodeOfRegister,
  bindWechatQrCode,
  checkBindSceneStr,
  verifyMobile,
  changeBindMobile,
  verifyEmail,
  changeBindEmail,
  unbindWechatQrCode,
  changePassword,
  UpgradeVip,
  ApiKey,
  ResetKey,

} from "@/libs/api/login";
import customButton from "@/renderer/components/common/button.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import { mapActions } from "vuex";
export default {
  components: { customButton, checkBox },
  data() {
    var validateUserName = (rule, value, callback) => {
      let errorMsgObj = {
        mobile: "手机号格式不正确",
        email: "邮箱格式不正确",
      };

      let reg =
        this.type === "mobile" ||
        (this.type === "password" && this.mode === "mobile")
          ? /^1\d{10}$/
          : /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === "" || !reg.test(value)) {
        callback(new Error(errorMsgObj[this.type]));
        // this.errMsgSet[rule.field] = errorMsgObj[this.type];
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validatePass = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/;
      if (value === "") {
        callback(new Error("密码不正确"));
        // this.errMsgSet[rule.field] = "密码不正确";
      } else if (value && value.length < 8) {
        callback(new Error("请输入不少于8位的密码"));
        // this.errMsgSet[rule.field] = "请输入不少于8位的密码";
      } else if (value && !reg.test(value)) {
        callback(new Error("密码 只能由字母和数字组成"));
        // this.errMsgSet[rule.field] = "密码 只能由字母和数字组成";
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validateRePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("确认密码不正确"));
        // this.errMsgSet[rule.field] = "确认密码不正确";
      } else if (value !== this.ruleForm.password) {
        callback(new Error("再次输入的密码不相同"));
        // this.errMsgSet[rule.field] = "再次输入的密码不相同";
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("验证码不正确"));
        // this.errMsgSet[rule.field] = "验证码不正确";
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    return {
      accountList: [
        {
          type: "mobile",
          title: "手机号",
          bindButtonText: "绑定手机号",
          changeButtonText: "更换手机号",
          nextTitle: "验证新手机号",
          placeholder: "未绑定，绑定后可帮您找回账号",
          propName: "mobile",
        },
        {
          type: "email",
          title: "邮箱",
          bindButtonText: "绑定邮箱",
          changeButtonText: "更换邮箱",
          nextTitle: "验证新邮箱",
          placeholder: "未绑定，绑定后可帮您找回账号",
          propName: "email",
        },
        {
          type: "wechat",
          title: "微信绑定",
          bindButtonText: "绑定微信",
          changeButtonText: "解除绑定",
          nextTitle: "验证新微信",
          placeholder: "绑定微信后，可通过扫码登录飞稻",
          propName: "wechat",
        },
        {
          type: "password",
          title: "登录密码",
          bindButtonText: "设置密码",
          changeButtonText: "设置密码",
          dialogTitle: "验证账户",
          nextTitle: "设置密码",
          placeholder: "绑定手机号或邮箱后可设置登录密码",
          propName: "password",
        },
        {
          type: "activationCode",
          title: "激活码",
          bindButtonText: "激活",
          changeButtonText: "已激活",
          dialogTitle: "验证账户",
          nextTitle: "设置密码",
          placeholder: "输入激活码后,即可成为VIP",
          propName: "Code",
        },
        {
          type: "ApiKey",
          title: "第三方应用",
          bindButtonText: "获取 API Key",
          changeButtonText: "获取 API Key",
          dialogTitle: "",
          nextTitle: "",
          placeholder:
            "如果你想把飞稻接入第三方应用，可以基于此AppId/AppSecret进行API的开发。点此 <a href='https://z7pooy8pe4.k.topthink.com/@feidao/jianjie.html' target='_blank' class='ApiDoc'>查看API文档</a>",
          propName: "ApiKey",
        },

      ],
      activationCode: "",
      //不做修改
      showForm: {
        mobile: "",
        email: "",
        wechat: "",
        password: "",
      },
      APIForm:{
        appId: "",
        appsecret: "",
      },

      ruleForm: {
        account: "",
        password: "",
        verification_code: "",
        password_confirmation: "",
        "new-account": "",
        "new-code": "",
      },
      dialogDetail: {
        visible: false,
        title: "",
        placeholder: "",
        item: {},
      },
      dialogApi: {
        visible: false,
        title: "",
        placeholder: "",
        item: {},
      },
      SecretType:'password',
      showSecret:false,

      type: "mobile", //
      mode: "mobile", //当前设置密码方式 email
      contentData: {
        mobile: [
          {
            props: "account", //绑定字段
            inputType: "text",
            propName: "mobile", //
            name: "当前绑定手机号",
            placeholder: "手机号",
          },
          {
            props: "verification_code",
            inputType: "text",
            propName: "mobileCode", //
            name: "验证码",
            placeholder: "请输入验证码",
            buttonText: "获取验证码",
          },
        ],
        email: [
          {
            props: "account",
            inputType: "email",
            propName: "email", //
            name: "邮箱",
            placeholder: "请输入邮箱",
          },
          {
            props: "verification_code",
            inputType: "text",
            propName: "mobileCode", //
            name: "验证码",
            placeholder: "请输入验证码",
            buttonText: "获取验证码",
          },
        ],
        mobileNext: [
          {
            props: "new-account",
            inputType: "text",
            propName: "mobile", //
            name: "新手机号",
            placeholder: "请输入新手机号",
            errorMsg: "手机号格式不正确",
          },
          {
            props: "new-code",
            inputType: "text",
            propName: "mobileCode", //
            name: "验证码",
            placeholder: "请输入验证码",
            errorMsg: "验证码不正确",
          },
        ],
        emailNext: [
          {
            props: "new-account",
            inputType: "email",
            propName: "email", //
            name: "新邮箱",
            placeholder: "请输入新邮箱",
            errorMsg: "邮箱格式不正确",
          },
          {
            props: "new-code",
            inputType: "text",
            propName: "mobileCode", //
            name: "验证码",
            placeholder: "请输入验证码",
            errorMsg: "验证码不正确",
          },
        ],
        password: [
          {
            props: "account", //绑定字段
            inputType: "text",
            propName: "mobile", //
            name: "当前绑定手机号",
            placeholder: "手机号",
          },
          {
            props: "verification_code",
            inputType: "text",
            propName: "mobileCode", //
            name: "验证码",
            placeholder: "请输入验证码",
            buttonText: "获取验证码",
          },
        ],
        passwordNext: [
          {
            props: "password", //绑定字段
            inputType: "password",
            propName: "password", //
            name: "新密码",
            placeholder: "请输入新密码",
          },
          {
            props: "password_confirmation", //绑定字段
            inputType: "password",
            propName: "password_confirmation", //
            name: "确认新密码",
            placeholder: "请再次输入新密码",
          },
        ],
      },
      isNextStep: false, //是否下一步
      isShowPass: false, //是否显示密码
      mobileCodeData: {
        hasCode: false,
        timer: null,
        countdownTime: 30, //倒计时s
        requesting: false, //请求中
      },
      wechatData: {
        img: "", //二维码
        codeDetail: {},
        timer: null, //定时检测是否扫码,
        duration: 2000, //ms = 1s，
      },
      errMsgSet: {
        account: "",
        password: "",
        verification_code: "",
        password_confirmation: "",
        "new-account": "",
        "new-code": "",
      },
      rules: {
        account: [{ validator: validateUserName, trigger: "submit" }],
        "new-account": [{ validator: validateUserName, trigger: "submit" }],
        password: [{ validator: validatePass, trigger: "submit" }],
        verification_code: [{ validator: validateCode, trigger: "submit" }],
        "new-code": [{ validator: validateCode, trigger: "submit" }],
        password_confirmation: [
          { validator: validateRePass, trigger: "submit" },
        ],
      },
      propMap: {
        //校验filed
        password: "password",
        mobile: "account",
        email: "account",
        verification_code: "verification_code",
        password_confirmation: "password_confirmation",
        "new-account": "new-account",
        "new-code": "new-code",
      },
    };
  },
  created() {
    this.getInfo();
  },
  computed: {
    currentData() {
      let mode = this.type;
      return this.contentData[!this.isNextStep ? mode : mode + "Next"];
    },
    otherMode() {
      // 其他模式
      let mode = this.mode;
      let other = mode === "mobile" ? "email" : "mobile";
      if (this.type === "password" && this.showForm[other]) {
        return {
          type: other,
          title: mode === "mobile" ? "邮箱" : "手机号",
        };
      } else {
        return "";
      }
    },
  },
  beforeDestroy() {
    this.wechatData.timer && clearInterval(this.wechatData.timer);
    this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
  },
  methods: {
    ...mapActions(["GetSecurityInformation"]),
    ResetApi(){
        //重置api
        ResetKey().then((res)=>{
        if (res.errorcode == 0) {
            this.APIForm.appsecret = res.data.appsecret;
            this.$message.success('重置成功！');
          }
      }).catch();
    },
    changeShow(){
      this.showSecret = !this.showSecret
     this.SecretType =  this.showSecret?'text':'password';
    },
    copySuccess() {
      this.$message.success('复制成功');
    },
    copyErr() {
      this.$message.error('复制失败');
    },
    getInfo() {
      this.GetSecurityInformation()
        .then((res) => {
          if (res.errorcode == 0) {
            this.showForm = res.data;
          }
        })
        .catch();
    },
    tapSubmitForm() {
      // 点击保存
      // 重置错误信息
      this.resetErrorMsg();

      let formName = "ruleForm";
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.toSave();
        } else {
          Object.keys(obj).forEach((v) => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    tapNextStep() {
      // 点击下一步
      // 重置错误信息
      this.resetErrorMsg();
      this.initCodeRelative();

      let formName = "ruleForm";
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.validCode();
        } else {
          Object.keys(obj).forEach((v) => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    tapClose(isDone = true) {
      //isDone:false 就是回调过来
      // 关闭弹窗
      this.isNextStep = false;
      this.initCodeRelative();
      this.resetForm();
      if (isDone) {
        this.dialogDetail.visible = false;
      }
      this.dialogDetail.item = {};
    },
    changeMode(mode) {
      // 改变登录/注册方式
      this.mode = mode;
      // 重置表单
      this.resetForm();
      this.initCodeRelative();

      this.contentData.password = this.contentData[this.mode];
      this.ruleForm["account"] = this.showForm[this.mode];
    },
    validCode() {
      // 校验手机号+验证码
      let { account, verification_code } = this.ruleForm;

      // 发请求通过后
      if (this.type === "mobile") {
        this.verifyMobile({
          mobile: account,
          verification_code: verification_code,
        });
      }

      // 手机号第一步
      // 邮箱第一步
      if (this.type === "email") {
        // this.isNextStep = true;
        // this.dialogDetail.title = this.dialogDetail.item.nextTitle;
        this.verifyEmail({
          email: account,
          verification_code: verification_code,
        });
      }

      // 密码
      if (this.type === "password" && !this.isNextStep) {
        if (this.mode === "mobile") {
          this.verifyMobile({
            mobile: account,
            verification_code: verification_code,
          });
        } else {
          this.verifyEmail({
            email: account,
            verification_code: verification_code,
          });
        }
        return;
      }
    },
    verifyMobile({ mobile, verification_code }) {
      //手机号 验证
      verifyMobile({ mobile, verification_code })
        .then((res) => {
          if (res.errorcode == 0) {
            this.ruleForm.token_string = res.data.token_string;
            if (!this.isNextStep) {
              this.isNextStep = true;
              this.dialogDetail.title = this.dialogDetail.item.nextTitle;
            }
          } else if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "mobile");
          }
        })
        .catch();
    },
    verifyEmail({ email, verification_code }) {
      //手机号 验证
      verifyEmail({ email, verification_code })
        .then((res) => {
          if (res.errorcode == 0) {
            this.ruleForm.token_string = res.data.token_string;
            if (!this.isNextStep) {
              this.isNextStep = true;
              this.dialogDetail.title = this.dialogDetail.item.nextTitle;
            }
          } else if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "email");
          }
        })
        .catch();
    },
    toSave() {
      let type = this.type;
      switch (type) {
        case "mobile":
          this.changePhone();
          break;
        case "email":
          this.changeEmail();
          break;
        case "password":
          this.changePwd();
          break;
      }
    },
    changePhone() {
      // 更换手机
      let params = {
        mobile: this.ruleForm["new-account"],
        verification_code: this.ruleForm["new-code"],
      };
      if (this.showForm.bind_mobile) {
        params.token_string = this.ruleForm.token_string;
      }
      changeBindMobile(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.$message.success(res.msg);
            this.dialogDetail.visible = false;
            window.location.reload();
          } else if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "mobile");
          }
        })
        .catch();
    },
    changeEmail() {
      // 更换邮箱
      let params = {
        email: this.ruleForm["new-account"],
        verification_code: this.ruleForm["new-code"],
      };
      if (this.showForm.bind_email) {
        params.token_string = this.ruleForm.token_string;
      }
      changeBindEmail(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.$message.success(res.msg);
            this.dialogDetail.visible = false;
            window.location.reload();
          } else if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "email");
          }
        })
        .catch();
    },
    changePwd() {
      // 更换密码
      let mode = this.mode;
      let params = {
        type: mode,
        password: this.ruleForm["password"],
        password_confirmation: this.ruleForm["password_confirmation"],
      };
      if (this.showForm.bind_email || this.showForm.bind_mobile) {
        params.token_string = this.ruleForm.token_string;
      }
      changePassword(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.$message.success(res.msg);
            this.dialogDetail.visible = false;
            this.getInfo();
          } else if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, this.mode);
          }
        })
        .catch();
    },
    initModalData() {
      // 初始化弹窗数据
      this.isNextStep = false;
      this.dialogDetail.item = {};
      Object.keys(this.ruleForm).forEach((v) => {
        this.ruleForm[v] = "";
      });
      this.mode = "mobile";
    },
    tapBtn(item) {
      // console.log(item)
      // 点击更换/绑定按钮
      let { type, propName } = item;
      if (propName === "ApiKey") {
        ApiKey().then((res)=>{
        if (res.errorcode == 0) {
            this.APIForm = res.data;
            this.dialogApi.visible = true;
          }
      }).catch();
        return;
      }

      let haValue = false; //当前点击是否有值
      if (type == "activationCode") {
        this.ToUpgrade();
        return;
      }
      this.initModalData();

      switch (propName) {
        case "mobile":
        case "email":
          if (
            (!this.showForm.bind_mobile && propName == "mobile") ||
            (!this.showForm.bind_email && propName == "email")
          ) {
            // 未绑定时，直接显示绑定
            this.isNextStep = true;
          }
          haValue = !!this.showForm[propName];
          this.ruleForm["account"] = this.showForm[propName];
          break;
        case "wechat":
          // 获取微信二维码
          // canunbind_wechat: 能否 绑定 ； bind_wechat： 是否已绑定
          if (
            this.showForm.wechat_buttion.status == 1 &&
            this.showForm.wechat_buttion.type === "bind"
          ) {
            // 可绑定
            this.bindWechatCode();
          } else if (
            this.showForm.wechat_buttion.status == 1 &&
            this.showForm.wechat_buttion.type === "unbind"
          ) {
            // 可解绑
            this.unbindWechatQrCode();
          }
          break;
        case "password":
          if (this.showForm.email) this.mode = "email";
          if (this.showForm.mobile) this.mode = "mobile";
          this.contentData.password = this.contentData[this.mode];
          haValue = !!this.showForm[this.mode];
          this.ruleForm["account"] = this.showForm[this.mode];
          break;
      }

      if (
        (propName === "wechat" && this.showForm.wechat_buttion.status == 0) ||
        (propName === "wechat" &&
          this.showForm.wechat_buttion.status == 1 &&
          this.showForm.wechat_buttion.type === "unbind")
      ) {
        return;
      }
      this.type = type;
      this.dialogDetail.visible = true;
      this.dialogDetail.item = item;
      if (type != "password") {
        this.dialogDetail.title = haValue
          ? item.changeButtonText
          : item.bindButtonText;
      } else {
        this.dialogDetail.title = item.dialogTitle;
      }
    },
    ToUpgrade() {
      //激活vip
      let valCode = this.activationCode.trim();

      UpgradeVip(valCode)
        .then((res) => {
          this.$message(res.msg);
        })
        .catch();
    },
    handleClose(done) {
      // 弹窗关闭前回调
      this.tapClose(false);
      done();
    },
    getInputType(type, props) {
      // 账号模式-显示密码时，密码框改为文本框
      if (props.indexOf("password") > -1 && this.isShowPass) return "text";
      return type;
    },
    getDisableStatus(item, index) {
      // 是否禁用 - 原本有值禁止修改
      return !!(!this.isNextStep && index == 0 && this.showForm[item.propName]);
    },
    getMobileCode() {
      // 获取验证码
      let mobile = this.ruleForm[this.isNextStep ? "new-account" : "account"];
      console.log("mobile", mobile);
      // 请求中
      if (this.mobileCodeData.requesting) return;
      // 倒计时中
      if (this.mobileCodeData.hasCode) return;
      if (!mobile) {
        let errMsg = this.type === "mobile" ? "请输入手机号！" : "请输入邮箱";
        this.$message.warning(errMsg);
        return;
      }
      this.mobileCodeData.requesting = true;
      this.mobileCodeData.hasCode = true;
      // 倒计时
      this.mobileCodeData.countdownTime--;
      this.mobileCodeData.timer = setInterval(() => {
        if (this.mobileCodeData.countdownTime <= 1) {
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.hasCode = false;
          this.mobileCodeData.countdownTime = 30;
        }
        this.mobileCodeData.countdownTime--;
      }, 1000);
      // 发送验证码
      if (
        this.type === "mobile" ||
        (this.type === "password" && this.mode === "mobile")
      ) {
        this.sendCodeOfPhone(mobile);
      } else {
        this.sendCodeOfEmail(mobile);
      }
    },
    sendCodeOfPhone(mobile) {
      // 发送手机 验证码
      let successFn = (res) => {
        this.mobileCodeData.requesting = false;
        if (res.errorcode == 0) {
          this.$message.success(res.msg);
        } else {
          if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "mobile");
          }
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      };
      let errorFn = (error) => {
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      };
      if (this.isNextStep) {
        sendMobileCodeOfRegister(mobile)
          .then((res) => {
            successFn(res);
          })
          .catch((error) => {
            errorFn(error);
          });
      } else {
        sendOfLoginMobileCode(mobile)
          .then((res) => {
            successFn(res);
          })
          .catch((error) => {
            errorFn(error);
          });
      }
    },
    sendCodeOfEmail(email) {
      // 发送邮箱 验证码
      let successFn = (res) => {
        this.mobileCodeData.requesting = false;
        if (res.errorcode == 0) {
          this.$message.success(res.msg);
        } else {
          if (res.errorcode == 1 && res.data) {
            // 错误提示
            this.errorMsgTransform(res.data, "email");
          }
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      };
      let errorFn = (error) => {
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      };
      if (this.isNextStep) {
        sendEmailCodeOfRegister(email)
          .then((res) => {
            successFn(res);
          })
          .catch(() => {
            errorFn();
          });
      } else {
        sendEmailCodeOfLogin(email)
          .then((res) => {
            successFn(res);
          })
          .catch(() => {
            errorFn();
          });
      }
    },
    resetErrorMsg() {
      // 重置错误信息
      Object.keys(this.errMsgSet).forEach((v) => (this.errMsgSet[v] = ""));
    },
    resetForm() {
      // 重置表单
      this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();

      Object.keys(this.ruleForm).forEach((v) => (this.ruleForm[v] = ""));
      Object.keys(this.errMsgSet).forEach((v) => (this.errMsgSet[v] = ""));
    },
    bindWechatCode() {
      // 获取绑定微信二维码
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      bindWechatQrCode()
        .then((res) => {
          if (res.errorcode == 0) {
            this.wechatData.codeDetail = res.data;
            this.wechatData.img = res.data.qrcode_url;
            // 检测扫码状态
            this.checkSceneStatus();
            this.$forceUpdate();
          }
        })
        .catch();
    },
    unbindWechatQrCode() {
      // 获取二维码-解绑微信
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      unbindWechatQrCode()
        .then((res) => {
          if (res.errorcode == 0) {
            this.$message.success(res.msg);
            this.getInfo();
          }
        })
        .catch();
    },
    checkSceneStatus() {
      // 检测扫码状态
      this.wechatData.timer = setInterval(() => {
        checkBindSceneStr(this.wechatData.codeDetail.scene_str)
          .then((res) => {
            if (res.errorcode == 0) {
              // 扫码成功
              if (res.data.status == 1 && res.data.bind_status) {
                this.$message.success("绑定成功！");
                this.wechatData.timer && clearInterval(this.wechatData.timer);
                this.dialogDetail.visible = false;
                this.getInfo();
                return;
              }
              // 随机字符串对应的值不存在或已过期
              if (res.data.status == -1) {
                this.wechatData.timer && clearInterval(this.wechatData.timer);
                this.bindWechatCode();
                return;
              }
            } else {
              this.wechatData.timer && clearInterval(this.wechatData.timer);
            }
          })
          .catch();
      }, this.wechatData.duration);
    },
    initCodeRelative() {
      // 初始化验证码相关记录
      this.mobileCodeData.hasCode = false;
      this.mobileCodeData.requesting = false;
      this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
      this.mobileCodeData.countdownTime = 30;
    },
    changeShowPass(value) {
      // 切换-是否显示密码
      this.isShowPass = value;
    },
    errorMsgTransform(data, type) {
      //type: email mobile wechat password
      // 错误提示转化
      Object.keys(data).forEach((v) => {
        if (this.isNextStep) {
          let propName = "";
          if (type === "email") {
            propName = v == "email" ? "new-account" : "new-code";
          } else if (type === "mobile") {
            propName = v == "mobile" ? "new-account" : "new-code";
          }
          this.errMsgSet[propName] = data[v][0];
        } else {
          this.errMsgSet[this.propMap[v]] = data[v][0];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ApiDoc {
  font-size: 14px;
  color: #e74362;
  line-height: 20px;
  font-weight: 500;
}
/deep/.API-dialog {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body{
  padding: 32px 32px 24px 32px;
  .from{
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    &>div{
      margin-top: 16px;
      display: inline-flex;
    align-items: center;
    justify-content: flex-start;
      &:first-child{
        margin-top: 24px;
      }
      .el-input {
        width: 69%;
        margin-right: 16px;
        height: 48px;
        input{
        background: #F5F5F5;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        box-shadow: none;
        }
      }
      .el-input__suffix{
        top: 50%;
      transform: translate(0,-35%);
      right: 16px;
      }
      .passwordEye{
        width: 20px;
        height: 16px;
        display: inline-block;
        cursor: pointer;
      }
      .eye-icon-show{
        background: url(~@/assets/user/eye-icon-show.png);
        background-size: 20px 16px;
        width: 20px;
        height: 16px;
        &:hover {
        background: url(~@/assets/user/eye-icon-show-hover.png);
        background-size: 20px 16px;
        }
      }
      .eye-icon-hide{
        background: url(~@/assets/user/eye-icon-hide.png); 
        background-size: 20px 16px;
        width: 20px;
        height: 16px;

        &:hover {
        background: url(~@/assets/user/eye-icon-hide-hover.png);
        background-size: 20px 16px;
        }
      }
    }
  }
  .copyBtn{
    border: 1px solid #E74362;
    border-radius: 4px;
    padding: 10px 26px;
    font-size: 14px;
    color: #E74362;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    &:hover{
      border-color: #B5102F;
      background: white;
    }
  }
  }
  .el-dialog__footer{
    padding: 0 32px 32px;
    .cancelBtn{
      background: #FFFFFF;
      border: 1px solid rgba(0,0,0,0.15);
      border-radius: 2px;
      &:hover{
        background: #F5F5F5;
      }
    }
    .confirmBtn{
      background: #E74362;
      border-radius: 2px;
      margin-left: 8px;
      &:hover{
        background: #B5102F;
      }
    }
    .btn{
      font-size: 14px;
      color: #595959;
      text-align: center;
      line-height: 22px;
      font-weight: 400;
      padding: 5px 16px;
    }
  }
  .Api_title {
    p{
      display: inline-block;
    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    font-weight: 500;
    vertical-align: middle;
    }
    &::before{
      content: '';
      width: 24px;
      height: 24px;
      background: url(~@/assets/Warning.png) no-repeat center;
      background-size: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }
  }
}

.account-security-container {
  box-sizing: border-box;
  padding: 16px 0;
  height: 100%;
    overflow:hidden scroll;
}
.account-security-body {
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 4px;
  padding: 8px 32px 0;
  width: 58%;
  height: 682px;
  max-width: 836px;
}
.account-security-item {
  height: 104px;
  padding: 0 8px;
  border-bottom: 1px solid #ededed;
}
.valid-code-btn {
  padding: 4px 15px;
  &.is-disabled {
    color: #a1a1a1;
  }
}
// 重置表单
/deep/.reset-form {
  &.el-form--label-top .el-form-item__label {
    padding-bottom: 8px;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
  }
  .el-form-item:not(:last-child) {
    margin-bottom: 16px;
  }
  input:disabled {
    background-color: #fafafa !important;
  }
}
// 微信扫码
.wechat-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 0;
  width: 100%;
  background: #ffffff;
  .wechat-code-wrapper {
    margin-bottom: 30px;
    box-sizing: border-box;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(~@/assets/user/red_frame1@3x.png),
      url(~@/assets/user/red_frame2@3x.png),
      url(~@/assets/user/red_frame3@3x.png),
      url(~@/assets/user/red_frame4@3x.png);
    background-repeat: no-repeat;
    background-position: left top, right top, left bottom, right bottom;
    background-size: 25px 25px;
  }
  .wechat-code-img {
    width: 140px;
    height: 140px;
    object-fit: fill;
  }
}
.account-security-header {
  height: 68px;
  border-bottom: 1px solid #d4d4d4;
}
button:disabled{
  background-color: #BFBFBF !important;
    color: #ffffff !important;
  span{
    color: #ffffff !important;
  }
  &:hover{
    background-color: #BFBFBF !important;
    color: #ffffff !important;
    span{
        color: #ffffff !important;
     }
  }
}
</style>